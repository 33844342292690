const constants = {
  // socketURL: 'wss://25n9w3cyue.execute-api.us-east-1.amazonaws.com/ua',
  // baseURL: 'https://g86fjl4gxi.execute-api.us-east-1.amazonaws.com/ua'
  // baseURL: 'http://3.90.141.4:8585/api'
  socketURL: 'wss://25n9w3cyue.execute-api.us-east-1.amazonaws.com/production',
  baseURL: 'https://g86fjl4gxi.execute-api.us-east-1.amazonaws.com/prod'
  // socketURL: 'ws://localhost:8585'
  // baseURL: 'http://localhost:8585/api'
};

export default constants;
