import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import PrivateRoute from './components/PrivateRoute';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications

const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);

const QRPrint = Loader(lazy(() => import('src/content/applications/QRprint')));

const QRSend = Loader(lazy(() => import('src/content/applications/QRsend')));

const Profile = Loader(lazy(() => import('src/content/applications/WebUsers')));

const Reports = Loader(lazy(() => import('src/content/applications/Reports')));

const ApprovedList = Loader(
  lazy(() => import('src/content/applications/ApprovedList'))
);

const Notifications = Loader(
  lazy(() => import('src/content/applications/Notfications'))
);

const AppUsers = Loader(
  lazy(() => import('src/content/applications/Appusers'))
);

const Projects = Loader(
  lazy(() => import('src/content/applications/Projects'))
);

const VideoGuide = Loader(
  lazy(() => import('src/content/applications/VideoGuide'))
);

const ContactUs = Loader(
  lazy(() => import('src/content/applications/ContactUs'))
);

const SignIn = Loader(lazy(() => import('src/content/applications/SignIn')));

const Privacy = Loader(lazy(() => import('src/content/applications/Privacy')));

const Overview = Loader(lazy(() => import('src/content/overview')));

// Components

const routes = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      }
    ]
  },
  {
    path: '/calendar',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <Transactions />
      }
    ]
  },
  {
    path: '/approved_list',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <ApprovedList />
      }
    ]
  },
  {
    path: '/report',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <Reports />
      }
    ]
  },
  {
    path: '/qrprint',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <QRPrint />
      }
    ]
  },
  {
    path: '/qrsend',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <QRSend />
      }
    ]
  },
  {
    path: '/notifications',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <Notifications />
      }
    ]
  },
  {
    path: '/projects',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Projects />
      }
    ]
  },
  {
    path: '/appusers',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <AppUsers />
      }
    ]
  },
  {
    path: '/webusers',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <Profile />
      }
    ]
  },
  {
    path: '/contactus',
    element: <BaseLayout />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <ContactUs />
      }
    ]
  },
  {
    path: '/video_guide',
    element: <BaseLayout />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <VideoGuide />
      }
    ]
  },
  {
    path: '/signin',
    element: <BaseLayout />,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="transactions" replace />
      // },
      {
        path: '',
        element: <SignIn />
      }
    ]
  },
  {
    path: '/privacy',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Privacy />
      }
    ]
  }
];

export default routes;
