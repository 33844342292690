import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: ''
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast(state, action) {
      state.message = action.payload;
    }
  }
});

export const { showToast } = toastSlice.actions;
export default toastSlice.reducer;
