import axios from 'axios';
import constants from './constants';
import store from '../store';
import { logout } from '../store/authSlice';
import { showToast } from '../store/toastSlice';

axios.defaults.baseURL = constants.baseURL;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Get token from Redux store state
    const token = store.getState().auth.token;

    // Set token in request headers if it exists
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Unauthorized error (invalid token)

      store.dispatch(logout());
    } else {
      store.dispatch(showToast(error.response.data.message));
    }
    return Promise.reject(error);
  }
);

export default axios;
