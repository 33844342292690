import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import toastReducer from './toastSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer
    // Add other reducers here if needed
  }
});

export default store;
